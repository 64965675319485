import * as React from "react"
import moment from 'moment';
import { MailIcon } from '@heroicons/react/solid'

import Layout from "../components/layout"
import Seo from "../components/seo"
import { motion } from "framer-motion";
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import HoverVideoPlayer from 'react-hover-video-player';
import { useLocation } from '@reach/router'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const posts = [
  {
    title: 'We launched on Product Hunt! 🚀',
    date: '05/08/2021',
    excerpt: 'Lorem ipsum dolor sit amet consectetur adipiscing elit, urna consequat felis',
    image: 'https://i.ytimg.com/vi/QXw1DYm6qx4/maxresdefault.jpg',
    href: '#',
    category: {
      name: 'Announcements',
      href: '#',
      colorClass: 'red'
    },
    author: {
      name: 'Matt Evans',
      twitter_url: 'https://twitter.com/motioncoder',
      image: 'https://pbs.twimg.com/profile_images/1372188526926774275/DcYHLkc1_400x400.jpg'
    },
    readingTime: '2mins'
  },
  {
    title: 'Create realistic mockups in After Effects',
    date: '05/08/2021',
    image: 'https://images.unsplash.com/photo-1501780392773-287d506245a5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80',
    href: '#',
    category: {
      name: 'New Features',
      href: '#',
      colorClass: 'yellow'
    },
    author: {
      name: 'Matt Evans',
      twitter_url: 'https://twitter.com/motioncoder',
      image: 'https://pbs.twimg.com/profile_images/1372188526926774275/DcYHLkc1_400x400.jpg'
    },
    excerpt: 'Learn how to create realistic mockups using After Effects',
    readingTime: '2mins'
  }
];

function Blog({data: { allGraphCmsCategory, allGraphCmsPost }, pageContext: { category = null }}) { 
  const { pathname } = useLocation();
  return (
  <Layout>
    <Seo title="Blog" />
    <section className="bg-gray-100">
      <div className="container mx-auto px-8 pt-12 pb-24">
        <div className="mb-12">
          <h1 className="text-4xl mb-2">Blog</h1>
          <p className="text-gray-400">Learn, access free resources and stay up to date with Edit Square</p>
        </div>
        <div className="flex flex-row flex-wrap -mx-5">
          <div className="w-full xl:w-3/4 px-5">
            <div className="relative max-w-lg mx-auto grid gap-8 lg:grid-cols-2 lg:max-w-none">
              {allGraphCmsPost.nodes.filter((post, i) => category ? post.category.slug == category : true).map((post, i) => (
                <motion.div
                  initial={{ y: 75, opacity: 0 }}
                  animate={{ y: '0', opacity: 1 }}
                  transition={{ ease: 'easeOut', duration: 0.5, delay: i * 0.25 }}
                  key={post.title} className="transform group flex flex-col rounded-xl shadow-lg overflow-hidden transition-shadow duration-400 hover:shadow-xl">
                    <div className="flex-shrink-0">
                      <Link to={`/blog/${post.slug}`} className="relative block overflow-hidden">
                        <div className="absolute top-4 right-4 z-20 text-white">
                          <div className="w-14 flex-shrink-0 flex flex-col text-center leading-none text-sm">
                            <span className="bg-white rounded-t-md py-2 text-gray-500">{moment(post.date).format("MMM")}</span>
                            <span className="bg-red-500 text-white py-2 rounded-b-md font-medium leading-none">{moment(post.date).format("Do")}</span>
                          </div>
                        </div>
                        <div class="relative bg-gray-200">
                        {post.coverImage.fileName.endsWith(".mp4")
                            ? <HoverVideoPlayer videoSrc={post.coverImage.url} restartOnPaused className="w-full h-full object-cover transform transition duration-400 group-hover:scale-110" />
                            // <video src={post.coverImage.url} />
                            : <Img
                                fluid={post.coverImage.localFile.childImageSharp.fluid}
                                className="w-full h-full object-cover transform transition duration-400 group-hover:scale-110"
                                fadeIn={false}
                              />
                        }
                          {/* <Img
                            fluid={post.coverImage.localFile.childImageSharp.fluid}
                            className="w-full h-full object-cover transform transition duration-400 group-hover:scale-110"
                            fadeIn={false}
                          /> */}
                        </div>
                      </Link>
                    </div>
                    <div className="flex-1 bg-white px-7 pt-6 pb-7 flex flex-col justify-between">
                      <div className="flex-1">
                        {post.category && (
                          <Link className={classNames(`text-${post.category.colour}-500 bg-${post.category.colour}-50 hover:bg-${post.category.colour}-100 inline-block text-xs font-medium rounded-full transition-all duration-350 px-2 py-0.5`)} to={`/blog/category/${post.category.slug}`}>
                          {post.category.name}
                          </Link>
                        )}
                        <Link to={`/blog/${post.slug}`} className="block mt-3">
                          <p className="text-2xl font-semibold text-gray-900 group-hover:text-blue-600 transition duration-200">{post.title}</p>
                          <p className="mt-2 text-sm text-gray-500">{post.excerpt}</p>
                        </Link>
                      </div>
                      <div className="mt-6 flex items-center">
                        <div className="flex-shrink-0">
                          <span className="sr-only">{post.author.name}</span>
                          <a href={post.author.twitter}>
                            <Img
                              fluid={post.author.picture.localFile.childImageSharp.fluid}
                              className="h-10 w-10 rounded-full"
                            />
                            {/* <img className="h-10 w-10 rounded-full" src={post.author.image} alt="" /> */}
                          </a>
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-gray-900">
                            <a href={post.author.twitter} className="hover:underline">
                              {post.author.name}
                            </a>
                          </p>
                          <div className="flex space-x-1 text-sm">
                            <span className="text-gray-400">{Math.round(post.content.text / 60)} mins read</span>
                          </div>
                        </div>
                      </div>
                    </div>
                </motion.div>
              ))}
            </div>

            <div className="py-12">
              <motion.div
                initial={{ scale: 0.7, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ ease: 'easeOut', duration: 0.45, delay: 0.5 }}
                className="py-10 px-6 topo-bg-blue rounded-xl sm:py-16 sm:px-12 lg:p-16 lg:flex lg:items-center">
                <div className="lg:w-0 lg:flex-1">
                  <h2 className="text-3xl font-extrabold tracking-tight text-white">Sign up to our newsletter</h2>
                  <p className="mt-4 max-w-3xl text-lg text-blue-100">
                    Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui Lorem cupidatat commodo. Elit sunt amet
                    fugiat.
                  </p>
                </div>
                <div className="mt-12 sm:w-full sm:max-w-md lg:mt-0 lg:ml-8 lg:flex-1">
                  <form className="sm:flex">
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                    </label>
                    <input
                      id="email-address"
                      name="email-address"
                      type="email"
                      autoComplete="email"
                      required
                      className="w-full border-white px-5 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-700 focus:ring-white focus:ring-opacity-50 rounded-md"
                      placeholder="Enter your email"
                    />
                    <button
                      type="submit"
                      className="mt-3 w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-700 focus:ring-white sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0"
                    >
                      Notify me
                    </button>
                  </form>
                  <p className="mt-3 text-sm text-blue-100 text-opacity-60">
                    We care about the protection of your data. Read our{' '}
                    <a href="#" className="font-medium underline">
                      Privacy Policy
                    </a>
                  </p>
                </div>
              </motion.div>
            </div>

          </div>

          <div className="w-full xl:w-1/4 px-5">
            <div className="mb-6">
              <ul>
                {allGraphCmsCategory.nodes.map((cat, i) => (
                  <Link to={`/blog/category/${cat.slug}`}>
                    <motion.li
                      key={cat.name}
                      initial={{ x: 50, opacity: 0 }}
                      animate={{ x: '0', opacity: 1 }}
                      transition={{ ease: 'easeOut', duration: 0.5, delay: i * 0.15 }}
                      className="group flex items-center cursor-pointer px-3 py-2 text-base leading-5 font-medium text-gray-700 rounded-md shadow-none hover:shadow hover:text-gray-900 hover:bg-gray-50"
                    >
                      <span
                        className={classNames('bg-' + cat.colour + '-500', 'w-2.5 h-2.5 mr-4 rounded-full')}
                        aria-hidden="true"
                      />
                      <span className="truncate">{cat.name}</span>
                      {/* <span className="inline-block ml-1 text-xs text-gray-400">(-)</span> */}
                    </motion.li>
                  </Link>
                ))}
              </ul>
            </div>
            <motion.div
              initial={{ y: 23, opacity: 0 }}
              animate={{ y: '0', opacity: 1 }}
              transition={{ ease: 'easeOut', duration: 0.5, delay: 0.65 }}
              className="bg-gradient-to-l from-white to-transparent p-5 mb-8 rounded-b-md rounded-r-md">
              <form>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  Stay up to date
                </label>
                <div className="flex">
                  <div>
                    <div className="relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <MailIcon className="h-5 w-5 text-gray-300" aria-hidden="true" />
                      </div>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 text-sm border-gray-300 rounded-md placeholder-gray-400"
                        placeholder="Enter email"
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="w-full flex items-center justify-center text-xs px-3 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-700 focus:ring-white sm:mt-0 sm:ml-1.5 sm:w-auto sm:flex-shrink-0"
                  >
                    Notify me
                  </button>
                </div>
              </form>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
}

export const blogPageQuery = graphql`
{
  allGraphCmsCategory(sort: {fields: name, order: ASC}) {
    nodes {
      stage
      slug
      name
      colour
    }
  }
  allGraphCmsPost(sort: {fields: date, order: DESC}) {
    nodes {
      id
      date
      excerpt
      slug
      title
      tags
      category {
        slug
        name
        colour
      }
      content {
        text: readingTimeInSeconds
      }
      author {
        id
        name
        twitter
        picture {
          localFile {
            childImageSharp {
              fluid(maxWidth: 80, maxHeight: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      coverImage {
        url
        fileName
        localFile {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
}
`;

export default Blog;
